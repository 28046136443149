<template>
  <div>
    <div class="myInfo_wrap">
      <van-form ref="form">
        <div class="myInfo_img">
          <div>
            <van-uploader
              :after-read="positiveAfterRead"
              v-model="positiveFileList"
              multiple
              :max-count="1"
              class="myInfo_img_li"
              :accept="'image/*'"
            >
              <van-image class="myInfo_img_li" :src="positiveLocal" />
            </van-uploader>
            <div class="myInfo_img_text">身份证正面</div>
          </div>
          <div>
            <van-uploader
              :after-read="otherAfterRead"
              v-model="otherFileList"
              multiple
              :max-count="1"
              class="myInfo_img_li"
              :accept="'image/*'"
            >
              <van-image class="myInfo_img_li" :src="positiveLocal" />
            </van-uploader>
            <div class="myInfo_img_text">身份证反面</div>
          </div>
        </div>
        <div class="myInfo_private">
          上传的信息仅用于签约，不会泄露您的隐私，请放心使用
        </div>
        <div class="myInfo_center">
          <van-field
            v-model="formData.name"
            required
            type="text"
            label="姓名:"
            name="name"
            placeholder="请输入姓名"
          />
          <van-field
            v-model="formData.card_code"
            required
            name="card_code"
            type="text"
            label="身份证号码:"
            placeholder="请输入身份证号码"
          />
          <van-field
            v-model="formData.phone"
            required
            disabled
            type="phone"
            label="手机号码:"
            name="phone"
            placeholder="请输入手机号码"
          />
          <van-field
            v-model="formData.bank_account"
            required
            type="text"
            label="银行卡号:"
            name="bank_account"
            placeholder="请输入银行卡号"
          />
          <van-field
            v-model="formData.bank_title"
            required
            type="text"
            label="开户行:"
            name="bank_title"
            placeholder="请输入开户行"
          />
          <van-field
            v-model="formData.address"
            required
            type="text"
            label="户籍地址:"
            name="address"
            placeholder="请输入户籍地址"
            class="myInfo_bottom_field"
          />
        </div>
        <!-- <div class="myInfo_button">
        <van-button type="info" @click="toMine">我的</van-button>
        <van-button v-if="formData.shiming_b==1" type="default" @click="noClick">提交审核</van-button>
        <van-button v-else type="info" @click="toConfirm">提交审核</van-button>
      </div> -->
      </van-form>
    </div>
    <div class="mytab">
      <div class="tabitem" @click="toMine">
        <div><span class="iconfont icon-wode"></span></div>
        <div>我的</div>
      </div>
      <div class="tabitem infoColor" @click="noClick" v-if="formData.shiming_b == 1">
        <div><span class="iconfont icon-wode"></span></div>
        <div>提交审核</div>
      </div>
      <div class="tabitem infoColor" @click="toConfirm" v-else>
        <div><span class="iconfont icon-wode"></span></div>
        <div>提交审核</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      positiveLocal: require("../assets/images/positive.png"),
      otherLocal: require("../assets/images/other.png"),
      positiveFileList: [],
      otherFileList: [],
      formData: {
        other: "",
        positive: "",
        name: "",
        card_code: "",
        phone: "",
        bank_account: "",
        bank_title: "",
        address: "",
        bank_pic: "",
      },
      access_token: "",
      pid: "",
      pnid: "",
      myCode: "", //实名状态码
      myMessage: "", //实名提示信息
      active: "",
      tabList: [
        { name: "message", value: "消息", icon: "iconfont icon-xiaoxi" },
        { name: "mine", value: "我的", icon: "iconfont icon-wode" },
        { name: "qianyueList", value: "合同", icon: "iconfont icon-hetong" },
      ],
    };
  },
  mounted() {
    let access_token = localStorage.getItem("access_token");
    this.access_token = access_token;
    let pid = localStorage.getItem("pid");
    this.pid = pid;
    let pnid = localStorage.getItem("pnid");
    this.pnid = pnid;
    this.getPhotoInfo();
    // if (!this.pid) {
    //   this.$dialog
    //     .alert({
    //       message: "拒绝访问！",
    //       showConfirmButton: false,
    //     })
    //     .then(() => {});
    // }
  },
  methods: {
    toMine() {
      this.$router.push({ name: "mine" });
    },
    noClick() {
      this.$toast({ message: "实名认证成功不用再提交了~" });
    },
    //获取个人信息
    getPhotoInfo() {
      this.$axios
        .get(
          "/tag/info?table=person&table2=person_salary&fieldone=id&fieldtwo=person_id&id= " +
            this.pid +
            '&fieldasname={"name":"username","card_code":"usercard_code","phone":"phone","address":"address","pic":"pic","status":"userstatus","shiming":"shiming_b"}'
        )
        .then((res) => {
          if (res.data.success) {
            let returnData = res.data.data;
            let phone = localStorage.getItem("mobile");
            if (phone != "") {
              this.formData.phone = phone;
            } else {
              this.formData.phone = returnData.phone;
            }
            this.formData.shiming_b = returnData.shiming_b;
            this.formData.name = returnData.username;
            this.formData.card_code = returnData.usercard_code;
            this.formData.bank_account = returnData.bank_account;
            this.formData.bank_title = returnData.bank_title;
            this.formData.address = returnData.address;
            this.formData.positive = returnData.pic.split(",")[0];
            this.formData.other = returnData.pic.split(",")[1];
            this.positiveFileList[0] = { url: this.formData.positive };
            this.otherFileList[0] = { url: this.formData.other };
            localStorage.setItem("realname", this.formData.name);
            localStorage.setItem("card_code", returnData.usercard_code);
          } else {
            this.$toast({ message: res.data.msg });
          }
        })
        .catch(() => {});
    },
    //上传正面照
    positiveAfterRead(file) {
      const param = new FormData();
      param.append("file", file.file);
      param.append("model", "front");
      param.append("pid", this.pid);
      this.$axios
        .post("/user/uploadEducation", param, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.access_token,
          },
        })
        .then((res) => {
          let returnData = res.data.data;
          if (res.data.data.code == 0) {
            this.formData.positive = returnData.imgurl;
            this.formData.name = returnData.data.姓名.words;
            this.formData.card_code = returnData.data.公民身份号码.words;
            this.formData.address = returnData.data.住址.words;
            localStorage.setItem(
              "card_code",
              returnData.data.公民身份号码.words
            );
            this.$toast({ message: "上传成功" });
          } else {
            this.$toast({ message: "请上传身份证正面照" });
          }
        });
    },
    //上传反面照
    otherAfterRead(file) {
      const param = new FormData();
      param.append("file", file.file);
      param.append("model", "back");
      param.append("pid", this.pid);
      this.$axios
        .post("/user/uploadEducation", param, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.access_token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.formData.other = res.data.data.imgurl;
            this.$toast({ message: "上传成功" });
          } else {
            this.$toast({ message: "请上传身份证反面照" });
          }
        });
    },
    //信息确认页面
    toConfirm() {
      if (this.formData.card_code) {
        if (this.formData.card_code.length != 18) {
          this.$toast({ message: "请输入18位身份证号码" });
          return false;
        }
      }
      this.formData.pid = this.pid;
      this.formData.pnid = this.pnid;
      let card_code = localStorage.getItem("card_code");
      if (!this.formData.positive) {
        this.$toast({ message: "请上传身份证正面照" });
      } else if (!this.formData.other) {
        this.$toast({ message: "请上传身份证反面照" });
      } else if (
        !this.formData.name ||
        !this.formData.card_code ||
        !this.formData.phone ||
        !this.formData.bank_account ||
        !this.formData.bank_title ||
        !this.formData.address
      ) {
        this.$toast({ message: "请完善个人信息" });
      } else if (this.formData.card_code != card_code) {
        this.$dialog
          .confirm({
            message: "您填写的信息部分与身份证照片不一致",
            confirmButtonText: "确认修改",
            cancelButtonText: "取消",
            width: "80%",
            type: "warning",
          })
          .then(() => {
            this.formData.data_statusb = 2;
            this.formData.pic =
              "" + this.formData.positive + "," + this.formData.other + "";
            this.$axios
              .get(
                "/person/getperstatusz?idNo=" +
                  this.formData.card_code +
                  "&name=" +
                  this.formData.name +
                  "&cardNo=" +
                  this.formData.bank_account
              )
              .then((res) => {
                if (res.data.success) {
                  this.myCode = res.data.data.code;
                  this.myMessage = res.data.data.message;
                  this.formData.shiming_msg = this.myMessage;
                  //判断是否实名成功
                  if (this.myCode == 0) {
                    this.formData.shiming = 1;
                    // this.$toast({ message: this.myMessage });
                    this.$toast({ message: "已提交审核" });
                    this.$axios
                      .post("/person/edits", this.formData, {
                        headers: {
                          "content-type": "application/json",
                          Authorization: this.access_token,
                        },
                      })
                      .then((res) => {
                        if (res.data.success) {
                          this.$router.push({ name: "mine" });
                        } else {
                          this.$toast({ message: res.data.msg });
                        }
                      })
                      .catch(() => {});
                  } else {
                    this.$dialog
                      .confirm({
                        message:
                          "实名认证失败,一般都是银行卡错误造成，\n请尝试更换银行卡",
                        confirmButtonText: "确认提交",
                        cancelButtonText: "取消",
                        width: "80%",
                        type: "warning",
                      })
                      .then(() => {
                        this.formData.shiming = 0;
                        this.$axios
                          .post("/person/edits", this.formData, {
                            headers: {
                              "content-type": "application/json",
                              Authorization: this.access_token,
                            },
                          })
                          .then((res) => {
                            if (res.data.success) {
                              this.$toast({ message: "已提交审核" });
                              this.$router.push({ name: "mine" });
                            } else {
                              this.$toast({ message: res.data.msg });
                            }
                          })
                          .catch(() => {});
                      })
                      .catch(() => {});
                    this.$toast({ message: res.data.message });
                    // this.$toast({ message: this.myMessage });
                  }
                } else {
                  this.$toast({ message: res.data.message });
                }
              })
              .catch(() => {});
          })
          .catch(() => {});
      } else {
        this.formData.pic =
          "" + this.formData.positive + "," + this.formData.other + "";
        this.$axios
          .get(
            "/person/getperstatusz?idNo=" +
              this.formData.card_code +
              "&name=" +
              this.formData.name +
              "&cardNo=" +
              this.formData.bank_account
          )
          .then((res) => {
            if (res.data.success) {
              this.myCode = res.data.data.code;
              this.myMessage = res.data.data.message;
              this.formData.shiming_msg = this.myMessage;
              //判断是否实名成功
              if (this.myCode == 0) {
                this.formData.shiming = 1;
                this.$toast({ message: "已提交审核" });
                this.$axios
                  .post("/person/edits", this.formData, {
                    headers: {
                      "content-type": "application/json",
                      Authorization: this.access_token,
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      this.$router.push({ name: "mine" });
                    } else {
                      this.$toast({ message: res.data.msg });
                    }
                  })
                  .catch(() => {});
              } else {
                this.$dialog
                  .confirm({
                    message:
                      "实名认证失败,一般都是银行卡错误造成，\n请尝试更换银行卡",
                    confirmButtonText: "确认提交",
                    cancelButtonText: "取消",
                    width: "80%",
                    type: "warning",
                  })
                  .then(() => {
                    this.formData.shiming = 0;
                    this.$axios
                      .post("/person/edits", this.formData, {
                        headers: {
                          "content-type": "application/json",
                          Authorization: this.access_token,
                        },
                      })
                      .then((res) => {
                        if (res.data.success) {
                          this.$toast({ message: "已提交审核" });
                          this.$router.push({ name: "mine" });
                        } else {
                          this.$toast({ message: res.data.msg });
                        }
                      })
                      .catch(() => {});
                  })
                  .catch(() => {});
                this.$toast({ message: res.data.message });
                // this.$toast({ message: this.myMessage });
              }
            }
          })
          .catch(() => {});
      }
    },
  },
};
</script>
<style scoped>
.confirm_center_list {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.0625rem solid #eee;
  height: 2.4rem;
  line-height: 2.4rem;
  color: #646566;
  font-size: 1rem;
}
.confirm_center_label {
  text-align: left;
  width: 30%;
  padding-left: 0.5rem;
  box-sizing: border-box;
}
.confirm_center_val {
  text-align: left;
  width: 72%;
  overflow: hidden;
  text-overflow: ellipsis; /* 文本溢出时显示省略号 */
  white-space: nowrap; /* 文本单行显示不换行； */
}
.myInfo_wrap {
  position: relative;
}
.myInfo_img {
  width: calc(100% - 2rem);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border: 0.0625rem solid #eee;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
}
.myInfo_img_li {
  width: 10rem;
  height: 8rem;
}
.myInfo_img_text {
  text-align: center;
  line-height: 1.2rem;
}
.myInfo_center {
  width: calc(100% - 2rem);
  margin: 0 auto;
  border: 0.0625rem solid #eee;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  padding-bottom: 1rem;
}

.myInfo_bottom_field {
  border-bottom: 0.0625rem solid #eee;
}
.myInfo_private {
  color: red;
  font-size: 0.8rem;
  width: calc(100% - 2rem);
  margin: 0 auto;
  margin-bottom: 1rem;
}
.infoColor{
  background-color: #1989fa;
  color: #fff;
  width: 70%;
}
</style>
<style>
.mytab .van-tabbar {
  height: 3.5rem !important;
}
.mytab .van-tabbar-item {
  font-size: 1rem !important;
}
.mytab .van-tabbar-item__icon {
  font-size: 0.8rem !important;
}
.mytab .van-icon {
  font-size: 1.5rem !important;
}
.mytab {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #eee;
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
  box-sizing: border-box;
  background-color: #fff;
}
.tabitem {
  width: 100%;
  height: 2.5rem;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width:30%;
  /* line-height: 3.5rem; */
}
.mytab .iconfont {
  font-size: 1.3rem;
}

/*  */
.van-toast__text {
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
}
.van-dialog__message {
  line-height: 1rem;
}
.van-dialog__confirm,
.van-dialog__confirm:active {
  color: #fff;
  background-color: #1989fa;
}
.van-dialog__cancel {
  background-color: #ebedf0;
}
.van-dialog__confirm,
.van-dialog__cancel {
  height: 2rem;
}
.myInfo_img .van-uploader__preview-image {
  width: 10rem;
  height: 8rem;
}
.myInfo_img .van-uploader__preview-delete {
  width: 1rem;
  height: 1rem;
}
.myInfo_img .van-uploader__preview-delete-icon {
  font-size: 1rem;
}

.myInfo_center .van-field__label {
  width: 6.2em;
}
.myInfo_center .van-cell {
  line-height: 2.4rem;
  font-size: 1rem;
}
.myInfo_button {
  width: calc(100% - 1rem);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.myInfo_button .van-button {
  font-size: 1rem;
  height: 2rem;
  border-radius: 0.5rem;
  width: 80%;
  margin: 0 1rem;
}
.myInfo_center .van-field__control {
  overflow: hidden;
  text-overflow: ellipsis; /* 文本溢出时显示省略号 */
  white-space: nowrap; /* 文本单行显示不换行； */
}
</style>
